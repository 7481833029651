import axios from "axios";

const api = axios.create({
  baseURL: "https://digimeka.neeto.dev/api",
  withCredentials: true,
});

// users
export const register = (payload) => api.post("/register", payload);
export const login = (payload) => api.post("/login", payload);
export const logout = () => api.get("/logout");
export const currentUser = () => api.get("/user");

//cards
export const searchCards = (payload) => api.post("/searchCards", payload);
export const rehydrateCards = (payload) => api.post("/rehydrateCards", payload);
export const getTotalCardCount = () => api.get("/getTotalCardCount");

//decks
export const addDeck = (payload) => api.post("/addDeck", payload);
export const getDecks = () => api.get("/getDecks");
