import { useState } from "react";
import Card from "../components/card/card";
import { SearchBar } from "../components/search/searchBar";

export default function Cards() {
  const [cardData, setCardData] = useState([]);
  return (
    <>
      <SearchBar setCardData={setCardData} />
      <div className="searchWrapper">
        <div className="searchCards">
          {cardData.map((card, index) => (
            <Card data={card} key={index} />
          ))}
        </div>
      </div>
    </>
  );
}
