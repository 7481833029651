import "./css/app.scss";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute";
import useUser from "./hooks/useUser";
import NavBar from "./components/navBar";
import Cards from "./views/cards";
import Build from "./views/build/build";
import Decks from "./views/decks";
import Login from "./views/login";
import Register from "./views/register";

function App() {
  const { appUser, setAppUser } = useUser();

  return (
    <div className="app">
      {/* <NavBar appUser={appUser} setAppUser={setAppUser} /> */}
      <Routes>
        <Route path="/" element={<Build />} />
        <Route
          path="/decks"
          element={
            <ProtectedRoute user={appUser}>
              <Decks />
            </ProtectedRoute>
          }
        />
        <Route path="/cards" element={<Cards />} />
        <Route path="/register" element={<Register appUser={appUser} />} />
        <Route
          path="/login"
          element={<Login appUser={appUser} setAppUser={setAppUser} />}
        />
      </Routes>
    </div>
  );
}

export default App;
