import styles from "./deckName.module.scss";
import { useState, useRef, useEffect } from "react";

export default function DeckName({ deckStore }) {
  const { setDeckName, deckName } = deckStore;
  const [editDeckName, setEditDeckName] = useState(false);
  const nameRef = useRef(null);

  const handleDeckNameInput = (e) => {
    if (e.key === "Enter") {
      setEditDeckName(false);
    }
  };

  useEffect(() => {
    if (nameRef.current) {
      editDeckName ? nameRef.current.focus() : nameRef.current.blur();
      const handleClickOutside = (e) => {
        if (!nameRef.current.contains(e.target)) {
          setEditDeckName(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [editDeckName]);

  return (
    <>
      {/* <span onClick={() => setEditDeckName(true)}> */}
      <input
        className={styles.deckName}
        ref={nameRef}
        spellCheck={false}
        type="text"
        maxLength="25"
        value={deckName}
        placeholder={"Deck name"}
        onClick={() => setEditDeckName(true)}
        onChange={(e) => setDeckName(e.target.value)}
        onKeyDown={handleDeckNameInput}
      />
      {/* </span> */}
    </>
  );
}
