import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../api";

export default function Register({ appUser }) {
  const [user, setUser] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [response, setResponse] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (appUser) {
      navigate("/");
    }
  }, [appUser, navigate]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (user.password === user.confirmPassword) {
      register(user)
        .then((res) => {
          setResponse(res.data.message);
          if (res.data.message === "Registration successful") {
            setTimeout(() => navigate("/"), 2000);
          }
        })
        .catch((err) => setResponse(err.response.data.message));
    } else {
      setResponse("Passwords don't match");
    }
  };

  return (
    <div className="formWrapper">
      <form onSubmit={handleSubmit}>
        <input
          autoFocus
          required
          type="text"
          placeholder="Email"
          name="email"
          value={user.email}
          onChange={handleOnChange}
        />
        <input
          required
          type="text"
          placeholder="Username"
          name="username"
          value={user.username}
          onChange={handleOnChange}
        />
        <input
          required
          type="password"
          placeholder="Password"
          name="password"
          value={user.password}
          onChange={handleOnChange}
        />
        <input
          required
          type="password"
          placeholder="Confirm Password"
          name="confirmPassword"
          value={user.confirmPassword}
          onChange={handleOnChange}
        />
        <button type="submit" name="register">
          Register
        </button>
      </form>
      <p>{response}</p>
      <Link to="/login">Login</Link>
    </div>
  );
}
