import styles from './deckSideBar.module.scss';
import panelStyles from '../panel/panel.module.scss';
import './sideBarAnimations.scss';
import DeckName from '../deckName/deckName';
import {
  TrashIcon,
  ClipboardIcon,
  PlusIcon,
  MinusIcon,
  SwitchHorizontalIcon,
  SearchIcon,
} from '@heroicons/react/solid';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SideBarTooltip from './sideBarTooltip';

export const deckListColour = {
  red: 'rgba(229, 0, 45, 0.8)',
  blue: 'rgba(0, 150, 224, 0.8)',
  yellow: 'rgba(254, 224, 0, 0.8)',
  green: 'rgba(0, 154, 106, 0.8)',
  black: 'rgba(34, 23, 20, 0.8)',
  purple: 'rgba(98, 85, 162, 0.8)',
  white: 'rgba(250, 250, 250, 0.8)',
};

export default function DeckSideBar({ deckStore, buildStore }) {
  const { cards, uniqueCards, eggs, uniqueEggs, getCardCountByLevel, getCardCountByType, clearDeckList } = deckStore;
  const { switchView, setSwitchView } = buildStore;
  const statHeaders = [2, 3, 4, 5, 6, 7, 'Option', 'Tamer'];

  const handleCopyURL = () => navigator.clipboard.writeText(window.location.href);
  const handleSwitchView = () => setSwitchView(!switchView);

  return (
    <>
      {/* closed */}
      <div className={switchView ? styles.deckSideBarCollapsed : styles.deckSideBar}>
        <CSSTransition in={switchView} unmountOnExit classNames='sidebar' timeout={{ enter: 500, exit: 500 }}>
          <div className={styles.deckSideBarFunctionsCollapsed}>
            <SideBarTooltip tip={switchView ? 'Search View' : 'Deck View'} position='left'>
              <SwitchHorizontalIcon onClick={handleSwitchView} />
            </SideBarTooltip>
            <SideBarTooltip tip='Copy URL' position='left'>
              <ClipboardIcon onClick={handleCopyURL} />
            </SideBarTooltip>
            <SideBarTooltip tip='Clear Deck' position='left'>
              <TrashIcon onClick={() => clearDeckList()} />
            </SideBarTooltip>
          </div>
        </CSSTransition>

        {/* open */}
        <CSSTransition in={!switchView} unmountOnExit classNames='sidebar' timeout={{ enter: 500, exit: 500 }}>
          {/* TODO: `panelStyles.panel` is ugly */}
          <div className={`${panelStyles.panel} ${styles.deckSideBarTop}`}>
            <div className={styles.deckSideBarName}>
              <DeckName deckStore={deckStore} />
            </div>
            <div className={styles.deckSideBarFunctions}>
              <SideBarTooltip tip={switchView ? 'Search View' : 'Deck View'} position='bottom'>
                <SwitchHorizontalIcon onClick={handleSwitchView} />
              </SideBarTooltip>
              <SideBarTooltip tip='Copy URL' position='bottom'>
                <ClipboardIcon onClick={handleCopyURL} />
              </SideBarTooltip>
              <SideBarTooltip tip='Clear Deck' position='bottom'>
                <TrashIcon onClick={() => clearDeckList()} />
              </SideBarTooltip>
            </div>
            <div className={styles.deckSideBarLevels}>
              {statHeaders.map((header) => (
                <div key={header}>
                  <span className={styles.levelHeader}>
                    {header === 'Option' || header === 'Tamer' ? header : `Lv.${header}`}
                  </span>
                  <span>
                    {header === 'Option' || header === 'Tamer'
                      ? getCardCountByType(`${header}`)
                      : getCardCountByLevel(`${header}`)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={!switchView} unmountOnExit classNames='sidebar' timeout={{ enter: 500, exit: 500 }}>
          {/* TODO: `panelStyles.panel` is ugly */}
          <div className={`${panelStyles.panel} ${styles.deckSideBarCards}`}>
            {/* eggs */}
            <ul>
              <li className={styles.deckSideBarCardAmount}>
                <span>Digitama {eggs.length + '/5'}</span>
              </li>
              <TransitionGroup enter={true} exit={true}>
                {uniqueEggs.map((card) => (
                  <CSSTransition key={card._id} classNames='fade-card' timeout={{ enter: 400, exit: 400 }}>
                    <Card card={card} store={deckStore} />
                  </CSSTransition>
                ))}
              </TransitionGroup>
              {/* cards */}
              <li className={styles.deckSideBarCardAmount}>
                <span>Deck {cards.length + '/50'}</span>
              </li>
              <TransitionGroup enter={true} exit={true}>
                {uniqueCards.map((card) => (
                  <CSSTransition key={card._id} classNames='fade-card' timeout={{ enter: 400, exit: 400 }}>
                    <Card card={card} store={deckStore} />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </ul>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}

function Card({ card, store }) {
  const { addToDeckList, removeFromDeckList, getUniqueCardCount, setScrollTarget } = store;
  return (
    <li
      className={styles.deckSideBarCard}
      style={{
        background: `no-repeat rgba(0,0,0,1)`,
        backgroundImage: `linear-gradient(90deg, ${deckListColour[card.color.toLowerCase()]} , transparent , ${
          deckListColour[card.color.toLowerCase()]
        }), url(${card.image})`,
        backgroundPosition: 'center 45%',
      }}
    >
      <div className={styles.deckSideBarCardCount}>
        <span>{getUniqueCardCount(card)}</span>
      </div>
      <div className={styles.deckSideBarCardName}>
        <span>{card.name}</span>
      </div>
      <div className={styles.deckSideBarCardNumber}>
        <span>{card.number}</span>
      </div>
      <div className={styles.options}>
        <button type='button' onClick={() => removeFromDeckList(card)}>
          <MinusIcon />
        </button>
        <button type='button' onClick={() => setScrollTarget({ ...card })}>
          <SearchIcon />
        </button>
        <button type='button' onClick={() => addToDeckList(card)}>
          <PlusIcon />
        </button>
      </div>
    </li>
  );
}
