import styles from "./build.module.scss";
import "./buildAnimations.scss";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import useDeckStore from "../../hooks/useDeckStore";
import useBuildStore from "../../hooks/useBuildStore";
import BuildSearch from "./buildSearch";
import BuildDeck from "./buildDeck";
import DeckSideBar from "../../components/sidebar/deckSideBar";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export default function Build() {
  const buildStore = useBuildStore();
  const deckStore = useDeckStore();
  const { setDeckList, deckURL, deckName, setDeckName } = deckStore;
  const { cards, switchView } = buildStore;
  let [searchParams, setSearchParams] = useSearchParams();
  const isMounted = useRef(false);

  useEffect(() => setDeckName(searchParams.get("name") ?? ""), []);

  // Decode deck
  // TODO: Migrate to deck store as a callback
  useEffect(() => {
    // Ignore initial effect invocation & wait until cards have been fetched
    if (!isMounted.current) {
      return;
    }

    const deck = [];
    const regex = /(.{24})(\d)/g;
    const validateRegex = /^(.{24}\d)+$/;
    try {
      const decodedURI = window.atob(
        decodeURIComponent(searchParams.get("deck"))
      );
      if (validateRegex.test(decodedURI)) {
        for (let match; (match = regex.exec(decodedURI)); ) {
          const id = match[1];
          const count = parseInt(match[2]);
          const card = buildStore.cards.find((card) => card._id === id);
          if (card) {
            for (let i = 0; i < count; i++) {
              deck.push(card);
            }
          }
        }
        setDeckList(deck);
      }
    } catch (error) {
      console.error(error);
      setDeckList([]);
    }
  }, [cards]);

  useEffect(() => {
    // Ignore initial effect invocation
    if (isMounted.current) {
      searchParams.set("name", deckName);
      setSearchParams(searchParams);
    }
  }, [deckName]);

  useEffect(() => {
    // Ignore initial effect invocation
    if (isMounted.current) {
      searchParams.set("deck", deckURL);
      setSearchParams(searchParams);
    }
  }, [deckURL]);

  useEffect(() => (isMounted.current = true), []);

  return (
    <div className={styles.buildWrapper}>
      <SwitchTransition>
        <CSSTransition
          key={switchView ? "buildDeck" : "buildSearch"}
          classNames="deckView"
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
        >
          {switchView ? (
            <BuildDeck deckStore={deckStore} />
          ) : (
            <BuildSearch deckStore={deckStore} buildStore={buildStore} />
          )}
        </CSSTransition>
      </SwitchTransition>
      <DeckSideBar deckStore={deckStore} buildStore={buildStore} />
    </div>
  );
}
