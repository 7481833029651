import styles from "./card.module.scss";
import { useState, useRef, useEffect } from "react";
import cardBack from "../../images/cardBack.png";
import useClickOutside from "../../hooks/useClickOutside";
import { PlusIcon, SearchIcon, MinusIcon } from "@heroicons/react/solid";
import Loader from "../loader/loader";

// TODO: Rename `data` to `card`
export default function Card({ data, deckStore, transformOrigin }) {
  const { addToDeckList, scrollTarget, removeFromDeckList } = deckStore;
  const [open, setOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const cardRef = useRef(null);
  const imageRef = useRef(null);
  useClickOutside(cardRef, () => setOpen(false));

  // Manually override the `src` to cancel associated network request
  useEffect(() => {
    const current = imageRef.current;
    return () => {
      current.src = "";
    };
  }, [imageRef]);

  useEffect(() => {
    if (scrollTarget && scrollTarget._id === data._id) {
      setOpen(true);
    }
  }, [scrollTarget]);

  return (
    <>
      <div className={styles.card}>
        <img className={styles.back} src={cardBack} draggable={false} />
        <Loader
          className={styles.loading}
          style={{ opacity: imageLoaded ? 0.0 : 1.0 }}
        />
        <div
          className={`${styles.front} ${open ? styles.modal : ""}`}
          style={{
            transformOrigin: open ? transformOrigin : undefined,
            opacity: imageLoaded ? 1.0 : 0.0,
          }}
          ref={cardRef}
        >
          <img
            ref={imageRef}
            src={data.image}
            onLoad={() => setImageLoaded(true)}
            draggable={false}
          />
          {imageLoaded && (
            <div className={styles.options}>
              <button type="button" onClick={() => removeFromDeckList(data)}>
                <MinusIcon />
              </button>
              <button type="button" onClick={() => setOpen(!open)}>
                <SearchIcon />
              </button>
              <button type="button" onClick={() => addToDeckList(data)}>
                <PlusIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
