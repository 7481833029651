import { useEffect, useState } from "react";
import { getDecks } from "../api";
import Deck from "../components/deck";
import { useNavigate } from "react-router-dom";

export default function Decks() {
  const [decks, setDecks] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getDecks().then((res) => {
      const data = res.data.data;
      setDecks(data ?? []);
    });
  }, []);

  return (
    <div className="decksWrapper">
      <div className="decks">
        {decks.map((deck, i) => (
          <Deck key={i} data={deck} />
        ))}
        <div className="newDeck" onClick={() => navigate("/")}>
          +
        </div>
      </div>
    </div>
  );
}
