import { useEffect, useState } from "react";
import { currentUser } from "../api";

export default function useUser() {
  const [user, setUser] = useState();

  useEffect(() => {
    currentUser().then((res) => {
      const currentUser = res.data.user;

      if (currentUser) {
        saveUser(currentUser);
      }
    });
  }, []);

  const saveUser = (user) => setUser(user);

  return {
    appUser: user,
    setAppUser: saveUser,
  };
}
