import { useEffect, useState } from "react";
import styles from "./deckSideBar.module.scss";

export default function SideBarTooltip({ position, tip, children }) {
  const [positionStyle, setPositionStyle] = useState();

  useEffect(() => {
    if (position === "left") {
      setPositionStyle(styles.sideBarTooltipLeft);
    }
    if (position === "bottom") {
      setPositionStyle(styles.sideBarTooltipBottom);
    }
  }, []);

  return (
    <div className={styles.sideBarTooltipWrapper}>
      {children}
      <div className={`${styles.sideBarTooltip} ${positionStyle}`}>
        <span>{tip}</span>
      </div>
    </div>
  );
}
