import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { currentUser, login } from "../api";

export default function Login({ appUser, setAppUser }) {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (appUser) {
      navigate("/decks");
    }
  }, [appUser, navigate]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    login(user).then((res) => {
      if (res.status === 200) {
        currentUser().then((res) => {
          const user = res.data.user;
          if (user) {
            setAppUser(user);
          }
        });
      }
    });
  };

  return (
    <div className="formWrapper">
      <form onSubmit={handleSubmit}>
        <input
          autoFocus
          type="text"
          placeholder="Email"
          name="email"
          value={user.email}
          onChange={handleOnChange}
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={user.password}
          onChange={handleOnChange}
        />
        <button type="submit" name="login">
          Login
        </button>
      </form>
      <Link to="/register">Register</Link>
    </div>
  );
}
