import { createSearchParams, useNavigate } from "react-router-dom";

export default function Deck({ data }) {
  let navigate = useNavigate();

  // todo: when loading deck, set deck name
  return (
    <div
      className="deck"
      onClick={() => {
        navigate({
          pathname: "/",
          search: createSearchParams({
            name: data.name,
            deck: data.url,
          }).toString(),
        });
      }}
    >
      <div className="deckImage">
        <img src="https://world.digimoncard.com/images/cardlist/card/ST9-06.png" />
      </div>
      <div></div>
      <div className="deckName">{data.name}</div>
    </div>
  );
}
