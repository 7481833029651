import { useState, useMemo, useCallback } from 'react';
import { addDeck } from '../api';

export const deckLimit = 50;
export const eggLimit = 5;
export const cardLimit = 4;

export default function useDeckStore() {
  const [deckName, setDeckName] = useState('');
  const [deckList, setDeckList] = useState([]);
  const [scrollTarget, setScrollTarget] = useState();

  const getCardCount = useCallback(
    (card) => deckList.filter((deckCard) => deckCard.number === card.number).length,
    [deckList]
  );
  const getCardCountByLevel = useCallback(
    (level) => deckList.filter((deckCard) => deckCard.lv.substring(3) === level).length,
    [deckList]
  );
  const getCardCountByType = useCallback(
    (type) => deckList.filter((deckCard) => deckCard.card_type === type).length,
    [deckList]
  );
  const getUniqueCardCount = useCallback(
    (card) => deckList.filter((deckCard) => deckCard._id === card._id).length,
    [deckList]
  );
  const getUniqueCards = useCallback((cards) => {
    // Removes duplicate entries by mapping card numbers to a set to remove duplicates
    // and then remapping them to their original object
    return [...new Set(cards.map((card) => card._id))].map((id) => {
      return cards.find((card) => card._id === id);
    });
  });

  const sortCardsByLevel = useCallback((cards) => {
    return cards.sort((a, b) => {
      const level = { a: a.lv.substring(3), b: b.lv.substring(3) };
      const cardType = { a: a.card_type, b: b.card_type };
      if (cardType.a === 'Tamer') return 1;
      if (cardType.b === 'Tamer') return -1;
      if (cardType.a === 'Option') return 1;
      if (cardType.b === 'Option') return -1;
      if (level.a < level.b) return -1;
    });
  });

  const cards = useMemo(() => sortCardsByLevel(deckList.filter((card) => card.card_type !== 'Digi-Egg')), [deckList]);
  const uniqueCards = useMemo(() => getUniqueCards(cards), [cards]);

  const eggs = useMemo(() => sortCardsByLevel(deckList.filter((card) => card.card_type === 'Digi-Egg')), [deckList]);
  const uniqueEggs = useMemo(() => getUniqueCards(eggs), [eggs]);

  const uniqueCardsAndEggs = useMemo(() => uniqueEggs.concat(uniqueCards), [uniqueCards, uniqueEggs]);

  const addToDeckList = useCallback(
    (card) => {
      if (getCardCount(card) + 1 <= cardLimit) {
        if (card.card_type === 'Digi-Egg') {
          if (eggs.length + 1 <= eggLimit) {
            setDeckList((deckList) => [...deckList, card]);
          }
        } else {
          if (cards.length + 1 <= deckLimit) {
            setDeckList((deckList) => [...deckList, card]);
          }
        }
      }
    },
    [deckList]
  );

  const removeFromDeckList = useCallback(
    (card) => {
      setDeckList((prevState) => {
        const deckList = [...prevState];
        const index = deckList.lastIndexOf(card);
        if (index !== -1) {
          deckList.splice(index, 1);
        }
        return deckList;
      });
    },
    [deckList]
  );

  const clearDeckList = () => setDeckList([]);

  const deckURL = useMemo(() => {
    let string = '';
    const deck = getUniqueCards(deckList);
    for (const card of deck) {
      const count = getUniqueCardCount(card);
      // Because a card and its alternate arts (can have more than one) share card number,
      // we encode using ID as this is unique between them.
      string += `${card._id.toString()}${count}`;
      // TODO: The following results in smaller encoding but requires us to rescrape the database
      // string += `${card.number.replace('-', '')}${count}${card.altenateVersion}`;
    }
    const encoded = window.btoa(encodeURIComponent(string));
    return encoded;
  }, [deckList]);

  const saveDeckList = useCallback(() => {
    addDeck({ name: deckName, url: deckURL, deck: deckList })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }, [deckList]);

  return {
    scrollTarget,
    setScrollTarget,
    deckName,
    setDeckName,
    deckList,
    setDeckList,
    clearDeckList,
    addToDeckList,
    removeFromDeckList,
    saveDeckList,
    deckURL,
    getCardCount,
    getUniqueCardCount,
    getCardCountByLevel,
    getCardCountByType,
    getUniqueCards,
    sortCardsByLevel,
    cards,
    uniqueCards,
    eggs,
    uniqueEggs,
    uniqueCardsAndEggs,
  };
}
