import styles from "./build.module.scss";
import DeckName from "../../components/deckName/deckName";
import MultiCard from "../../components/card/multiCard";

// TODO show number of cards in deck / 50 and number of eggs in deck / 5
export default function BuildDeck({ deckStore }) {
  const { uniqueEggs, uniqueCards } = deckStore;
  return (
    <div className={styles.deckWrapperBuild}>
      <div className={styles.deckViewAppBar}>
        <div className={styles.deckViewName}>
          <DeckName deckStore={deckStore} />
        </div>
      </div>
      <div className={styles.deckCards}>
        {uniqueEggs.map((card) => (
          <MultiCard key={card._id} data={card} deckStore={deckStore} />
        ))}
        {uniqueCards.map((card) => (
          <MultiCard key={card._id} data={card} deckStore={deckStore} />
        ))}
      </div>
    </div>
  );
}
