import styles from './searchBar.module.scss';
import panelStyles from '../panel/panel.module.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import digimonTypes from './filterData/digimonTypes.json';
import cardTypes from './filterData/cardTypes.json';
import digimonAttributes from './filterData/digimonAttributes.json';
import rarities from './filterData/rarities.json';
import colourTypes from './filterData/colours.json';
import cardSets from './filterData/cardSets.json';
import digimonForms from './filterData/digimonForms.json';
import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'debounce';
import { forwardRef } from 'react';

const animatedComponents = makeAnimated();

const selectStyles = {
  container: (provided) => ({
    ...provided,
    padding: 5,
  }),
  control: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: 12,
    '::-webkit-scrollbar': {
      width: 4,
      height: 0,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  }),
};

export const SearchBar = forwardRef(({ style, buildStore }, ref) => {
  const { cards, setFilteredCards } = buildStore;
  const [name, setName] = useState('');
  const [cardSet, setCardSet] = useState('');
  const [cardType, setCardType] = useState('');
  const [level, setLevel] = useState('');
  const [playCost, setPlayCost] = useState('');
  const [attribute, setAttribute] = useState('');
  const [rarity, setRarity] = useState('');
  const [digimonType, setDigimonType] = useState('');
  const [digimonForm, setDigimonForm] = useState('');
  const [colours, setColours] = useState([]);

  useEffect(() => {
    const colour = colours.map((colour) => colour.value);
    setFilteredCards(
      cards.filter(
        (card) =>
          card.name.toLowerCase().includes(name.toLowerCase()) &&
          (cardSet === null ||
            cardSet.length === 0 ||
            card.number.toLowerCase().includes(cardSet.value.toLowerCase())) &&
          (level === null || level.length === 0 || card.lv === level.value) &&
          (playCost === null || playCost.length === 0 || card.play_cost === playCost.value) &&
          (digimonType === null || digimonType.length === 0 || card.type.includes(digimonType.value)) &&
          (cardType === null || cardType.length === 0 || card.card_type === cardType.value) &&
          (attribute === null || attribute.length === 0 || card.attribute === attribute.value) &&
          (rarity === null || rarity.length === 0 || card.rarity === rarity.value) &&
          (colours.length === 0 || colour.includes(card.color.toLowerCase()))
      )
    );
  }, [cards, name, cardSet, colours, cardType, level, playCost, attribute, rarity, digimonType]);

  const handleNameChange = useCallback(debounce((e) => setName(e.target.value), 100));

  // react-select options
  const levelOptions = Array.from({ length: 6 }, (v, i) => ({ value: `Lv.${i + 2}`, label: `${i + 2}` }));
  const playCostOptions = Array.from({ length: 17 }, (v, i) => ({ value: `${i}`, label: `${i}` }));
  const digimonTypeOptions = digimonTypes.map((type) => ({ value: type, label: type }));
  const cardTypeOptions = cardTypes.map((type) => ({ value: type, label: type }));
  const attributeOptions = digimonAttributes.map((attribute) => ({ value: attribute, label: attribute }));
  const rarityOptions = rarities.map((rarity) => ({ value: rarity, label: rarity }));
  const colourOptions = colourTypes.map((colour) => ({ value: colour, label: colour }));
  const cardSetOptions = cardSets.map((set) => ({ value: set, label: set }));
  const digimonFormOptions = digimonForms.map((form) => ({ value: form, label: form }));

  return (
    // TODO: `panelStyles.panel` is ugly
    <div ref={ref} className={`${panelStyles.panel} ${styles.searchBarWrapper}`} style={style}>
      <div className={styles.searchBar}>
        <div className={styles.searchFilters}>
          {/* name filter */}
          <input
            className={styles.searchName}
            spellCheck={false}
            autoFocus
            type='text'
            placeholder='Name...'
            onChange={handleNameChange}
          />
          {/* set filter */}
          <Select
            placeholder='Card Set...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setCardSet}
            options={cardSetOptions}
          />
          {/* level filter */}
          <Select
            placeholder='Level...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setLevel}
            options={levelOptions}
          />
          {/* play cost filter */}
          <Select
            placeholder='Play Cost...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setPlayCost}
            options={playCostOptions}
          />
          {/* digimon type filter */}
          <Select
            placeholder='Digimon Type...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setDigimonType}
            options={digimonTypeOptions}
          />
          {/* digimon form filter */}
          <Select
            placeholder='Digimon Form...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setDigimonForm}
            options={digimonFormOptions}
          />
          {/* card type filter */}
          <Select
            placeholder='Card Type...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setCardType}
            options={cardTypeOptions}
          />
          {/* attribute filter */}
          <Select
            placeholder='Attribute...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setAttribute}
            options={attributeOptions}
          />
          {/* rarity filter */}
          <Select
            placeholder='Rarity...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            onChange={setRarity}
            options={rarityOptions}
          />
          {/* colour filter */}
          <Select
            placeholder='Colours...'
            styles={selectStyles}
            spellCheck={false}
            isClearable
            isMulti
            components={animatedComponents}
            onChange={setColours}
            options={colourOptions}
          />
        </div>
      </div>
    </div>
  );
});
