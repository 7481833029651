import { forwardRef, useEffect, useMemo } from 'react';
import styles from './build.module.scss';
import { SearchBar } from '../../components/search/searchBar';
import Card from '../../components/card/card';
import { FixedSizeGrid as Grid } from 'react-window';
import useMeasure from 'react-use-measure';
import { useRef } from 'react';
import Loader from '../../components/loader/loader';

const CARD_WIDTH = 180;
const CARD_HEIGHT = 250;
const GUTTER_SIZE = 20;

const InnerGridContainer = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      paddingLeft: GUTTER_SIZE,
      paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
));

const Cell = ({ data, columnIndex, rowIndex, style }) => {
  const { columnCount, rowCount, cards, deckStore } = data;
  const index = columnIndex + rowIndex * columnCount;
  if (index >= cards.length) {
    return null;
  }

  const horizontal = columnIndex == 0 ? 0 : columnIndex == columnCount - 1 ? 100 : 50;
  const vertical = rowIndex == 0 ? 0 : rowIndex == rowCount - 1 ? 100 : 50;
  return (
    <div
      style={{
        ...style,
        left: style.left + GUTTER_SIZE,
        top: style.top + GUTTER_SIZE,
        width: style.width - GUTTER_SIZE,
        height: style.height - GUTTER_SIZE,
      }}
    >
      {/* Use key to force remount when card data changes */}
      <Card
        key={cards[index]._id}
        data={cards[index]}
        deckStore={deckStore}
        transformOrigin={`${horizontal}% ${vertical}%`}
      />
    </div>
  );
};

export default function BuildSearch({ deckStore, buildStore }) {
  const { filteredCards: cards, loadingCards } = buildStore;
  // TODO: Convert this into a callback instead of state
  const { scrollTarget } = deckStore;

  const gridRef = useRef(null);
  const [searchBarRef, { height: searchBarHeight }] = useMeasure();
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure();
  const columnCount = useMemo(() => Math.floor((containerWidth - GUTTER_SIZE) / CARD_WIDTH), [containerWidth]);
  const rowCount = useMemo(() => Math.ceil(cards.length / columnCount), [cards, columnCount]);

  useEffect(() => {
    if (gridRef.current && scrollTarget) {
      const index = cards.findIndex((card) => card._id === scrollTarget._id);
      const columnIndex = index % columnCount;
      const rowIndex = Math.floor(index / columnCount);
      gridRef.current.scrollToItem({ align: 'center', columnIndex, rowIndex });
    }
  }, [scrollTarget]);

  return (
    <div ref={containerRef} className={styles.searchWrapperBuild}>
      <SearchBar ref={searchBarRef} buildStore={buildStore} />
      <Loader className={styles.loading} style={{ opacity: loadingCards ? 1.0 : 0.0 }} />
      <div className={styles.loading} style={{ color: 'white', opacity: !loadingCards && !cards.length ? 1.0 : 0.0 }}>
        No Results
      </div>
      <Grid
        ref={gridRef}
        className={styles.cardGrid}
        style={{ '--offset': `${searchBarHeight}px` }}
        width={containerWidth}
        height={containerHeight - searchBarHeight}
        columnWidth={CARD_WIDTH}
        columnCount={columnCount}
        rowHeight={CARD_HEIGHT}
        rowCount={rowCount}
        overscanRowCount={4}
        itemData={{ columnCount, rowCount, cards, deckStore }}
        innerElementType={InnerGridContainer}
      >
        {Cell}
      </Grid>
    </div>
  );
}
