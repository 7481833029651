import { useState, useEffect } from 'react';
import { searchCards } from '../api';

export default function useBuildStore() {
  const [switchView, setSwitchView] = useState(false);
  const [loadingCards, setLoadingCards] = useState(true);
  const [filteredCards, setFilteredCards] = useState([]);
  const [cards, setCards] = useState([]);

  // Fetch cards
  // TODO: Move card array into a react context
  useEffect(() => searchCards().then((res) => res.data.data && setCards(res.data.data)), []);
  useEffect(() => cards.length && setLoadingCards(false) , [cards]);

  return {
    cards,
    filteredCards,
    setFilteredCards,
    switchView,
    setSwitchView,
    loadingCards,
  };
}
