import styles from './card.module.scss';
import './cardAnimations.scss';
import { useState, useRef, useEffect } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { MinusIcon, PlusIcon, SearchIcon } from '@heroicons/react/solid';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import Modal from '../modal/modal';

export default function MultiCard({ data, deckStore }) {
  const { addToDeckList, removeFromDeckList, getUniqueCardCount } = deckStore;
  const [open, setOpen] = useState(false);
  const [multiCardData, setMultiCardData] = useState([]);
  const cardRef = useRef(null);
  useClickOutside(cardRef, setOpen);
  useEffect(() => {
    setMultiCardData([]);
    for (let i = 0; i < getUniqueCardCount(data); i++) {
      setMultiCardData((prevState) => [...prevState, data]);
    }
  }, [addToDeckList, removeFromDeckList]);
  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={open ? 'open' : 'closed'}
          classNames='multiCardModal'
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        >
          {open ? (
            <div className={styles.multiCardModal}>
              <div className={styles.card} ref={cardRef}>
                <img src={data.image} draggable={false} />
                <div className={styles.options}>
                  <button type='button' onClick={() => removeFromDeckList(data)}>
                    <MinusIcon />
                  </button>
                  <button type='button' onClick={() => setOpen(!open)}>
                    <SearchIcon />
                  </button>
                  <button type='button' onClick={() => addToDeckList(data)}>
                    <PlusIcon />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </CSSTransition>
      </SwitchTransition>
      <div className={styles.card}>
        <TransitionGroup appear={true} enter={true}>
          {multiCardData.map((_card, i) => (
            <CSSTransition key={i} classNames='multiCard' timeout={200}>
              <div className={styles.multiCard}>
                <img src={data.image} draggable={false} />
                {i === 0 && (
                  <div className={styles.options}>
                    <button type='button' onClick={() => removeFromDeckList(data)}>
                      <MinusIcon />
                    </button>
                    <button type='button' onClick={() => setOpen(!open)}>
                      <SearchIcon />
                    </button>
                    <button type='button' onClick={() => addToDeckList(data)}>
                      <PlusIcon />
                    </button>
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </>
  );
}
